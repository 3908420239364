import { createMuiTheme, responsiveFontSizes } from '@material-ui/core'

let theme = createMuiTheme({
  typography: {
    fontFamily: ['Poppins', 'sans-serif'].join(',')
  },
  palette: {
    primary: {
      main: '#5c2a72'
    },
    secondary: {
      main: '#fffb5c'
    },
    text: {
      default: '#707070'
    }
  }
})

theme = responsiveFontSizes(theme)

export default theme
